import React, { useEffect, useState } from 'react';
import {
  preRegistrationDataForm1,
  preRegistrationDataForm2,
  dependentFieldsArray,
  nonDependentFieldsArray
} from '../../constants/preRegistrationData';
import PreRegistrationSingleField from '../preRegistrationSingleField';
import PopupModal from '../campaigns/popupModals';
import { Link } from 'react-router-dom';
import { convertFormDataToJson } from '../../helpers/profileHelpers';
import { sendMail } from '../../helpers/emailSender';
import {
  dateFormatYYYYMMDD,
  monthDifferenceCalculator
} from '../component/dateFormat';
import InfoIcon from '../../assets/icons/infoGray_icon.svg';
import toast from 'react-hot-toast';
import underReviewGreenIcon from '../../assets/icons/underReviewGreenIcon.svg';
import Loader from '../loader';
var formStepId = 'form_step_1';
var errMsgsArray = [];
var surveyStep, surveyStatus, ngoRejectedDate, isConditionalApprove;
var hideExternalAudit = false;
var hideAuditYearIndex = null;
var currentYear = null;
var regYear = null;
const $ = window.$;
export default function PreRegistrationForm(props) {
  const [formStep1, setFormStep1] = useState(true);
  const [formStep2, setFormStep2] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [errMsgsArr, setErrMsgsArr] = useState();
  const [showNextStepIndicator, setShowNextStepIndicator] = useState(false);
  const [backBtn, setBackBtn] = useState(false);
  const [progress, setProgress] = useState(0);
  const [preRegReject, setPreRegReject] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [registrationDate, setRegistrationDate] = useState(null);
  const [loadingTimer, setLoadingTimer] = useState(true);

  setTimeout(() => {
    setLoadingTimer(false);
  }, 1000);

  const getNgoId = () => {
    if (props.state.ngo.ngoProfile && props.state.ngo.ngoProfile !== '') {
      return props.state.ngo.ngoProfile.ngo_id;
    } else if (props.state.login.userInfo) {
      return props.state.login.userInfo.sub;
    }
  };

  useEffect(() => {
    props.getFcraPurpose();
    props.getSavedNGOData(getNgoId());
  }, []);
  useEffect(() => {
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, []);

  useEffect(() => {
    var ngoSavedData;
    isConditionalApprove = props.state.ngo.ngoProfile.is_conditionally_approved;
    if (Object.keys(props.state.register.ngoSavedData).length > 0) {
      ngoSavedData = props.state.register?.ngoSavedData;
      surveyStep = ngoSavedData['at_survey_step'];
      surveyStatus = ngoSavedData['survey_status'];
      setRegistrationDate(ngoSavedData['legal_compliance']?.['date_of_incorp']);
    }
    const hash = window.location.hash.substr(1);
    if (surveyStep === '2' && !backBtn && hash === 'mettasocial_preonboard') {
      handleFormStep('form_step_2');
      setProgress(100 / 2);
    } else if (hash === 'mettasocial_preonboard') {
      window.location.href = '/ngo/preRegistration#mettasocial_preonboard';
    } else if (surveyStep === '2' && backBtn) {
      handleFormStep('form_step_1');
      window.location.href = '/ngo/preRegistration#mettasocial_preonboard';
      setProgress(100 / 2);
    } else if (isConditionalApprove === true && surveyStatus !== 'approved') {
      window.location.href = '/ngo/profile';
    } else if (
      (surveyStep === '3' || surveyStep === '2') &&
      surveyStatus === 'rejected' &&
      !backBtn
    ) {
      ngoRejectedDate = monthDifferenceCalculator(
        ngoSavedData['preonboarding_updated_date'],
        3
      );
      const dateOnly = new Date(ngoRejectedDate).toISOString().slice(0, 10);
      const today = new Date().toISOString().slice(0, 10);
      if (dateOnly === today) {
        handleFormStep('form_step_1');
        setProgress(100 / 3);
      } else {
        setPreRegReject(true);
      }
    } else if (surveyStep === '3' && surveyStatus === 'submitted' && !backBtn) {
      handleFormStep('form_step_1');
      setProgress(100 / 1);
    } else if (surveyStep === '3' && surveyStatus === 'approved' && !backBtn) {
      window.location.href = '/ngo/profile';
    } else if (surveyStep === '1' && !backBtn) {
      handleFormStep('form_step_1');
      setProgress(100 / 3);
    } else if (surveyStep === '2' && !backBtn) {
      handleFormStep('form_step_2');
      setProgress(100 / 2);
    }
  }, [props.state.register.ngoSavedData, formStep1, formStep2]);

  function handleFormStep(StepIdOfForm) {
    errMsgsArray = [];
    setErrMsgsArr([]);
    formStepId = StepIdOfForm;
    if (StepIdOfForm === 'form_step_1') {
      setShowNextStepIndicator(false);
      setFormStep1(true);
      setFormStep2(false);
      setErrMsgsArr([]);
    } else if (StepIdOfForm === 'form_step_2') {
      setShowNextStepIndicator(true);
      setFormStep1(false);
      setFormStep2(true);
    }
  }

  function handleBackButton(e) {
    setBackBtn(true);
    props.getSavedNGOData(getNgoId());
    handleFormStep('form_step_1');
  }
  function handleFormSubmission1(e, formStep1, formStep2) {
    e.preventDefault();
    e.persist();
    setBackBtn(false);
    let data = convertFormDataToJson(new FormData(e.target));
    var ky = '';
    var financeReportArr = [];
    let financeReport = {
      report: '',
      year: ''
    };
    setErrMsgsArr([]);
    errMsgsArray = [];
    Object.keys(data).map(key => {
      ky = key;
      if (key.includes('report')) {
        let financeYear = key.replace(/.*_/, ''); //get year
        if (data[key] !== undefined && data[key]?.length > 0) {
          financeReport = {
            report: [data[key]],
            year: financeYear
          };
          financeReportArr.push(financeReport);
        }
      }
      if (ky !== 'gst_num') {
        if (
          data[ky] === '' ||
          data[ky] === null ||
          data[ky] === undefined ||
          data[ky] === true
        ) {
          errMsgsArray[ky] = true;
        } else {
          errMsgsArray[ky] = false;
        }
      } else {
        if (ky === 'gst_num') {
          const gstinRegexPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[ZSCD]{1}[A-Z0-9]{1}$/;
          if (gstinRegexPattern.test(data[ky])) {
            errMsgsArray[ky] = false;
          } else {
            errMsgsArray[ky] = true;
          }
        }
        // if (data[ky]?.length === 0) {
        //   errMsgsArray[ky] = false;
        // } else {
        //   const gstinRegexPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[0-9A-Z]{1}[0-9]{1}$/;
        //   if (gstinRegexPattern.test(data[ky])) {
        //     errMsgsArray[ky] = false;
        //   } else {
        //     errMsgsArray[ky] = true;
        //   }
        // }
      }

      if (ky === 'tan_num') {
        const regexPattern = /[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
        if (regexPattern.test(data[ky])) {
          errMsgsArray[ky] = false;
        } else {
          errMsgsArray[ky] = true;
        }
      }

      if (ky === 'fcra_registration_number') {
        if (data[ky] !== '') {
          if (data[ky]?.length !== 9) {
            errMsgsArray[ky] = true;
          } else {
            errMsgsArray[ky] = false;
          }
        }
      }
      if (ky === 'csr_registration_number') {
        const regexPattern = /[C]{1}[S]{1}[R]{1}[0-9]{8}$/;
        if (regexPattern.test(data[ky])) {
          errMsgsArray[ky] = false;
        } else {
          errMsgsArray[ky] = true;
        }
      }
      if (ky === 'pan_num') {
        const panRegexPattern = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        if (panRegexPattern.test(data[ky])) {
          errMsgsArray[ky] = false;
        } else {
          errMsgsArray[ky] = true;
        }
      }
      setErrMsgsArr([...errMsgsArray]);
    });

    if (errMsgsArray !== undefined) {
      {
        dependentFieldsArray.map((item, index) => {
          for (let i = 0; i < item.dependentFields?.length; i++) {
            if (!item.reverseCondition && item.formStep === formStepId) {
              if (
                item.dependentFields[i] in errMsgsArray &&
                errMsgsArray[item.jsonfield] === false
              ) {
                errMsgsArray[item.dependentFields[i]] = false;
              } else if (
                !(item.dependentFields[i] in errMsgsArray) &&
                errMsgsArray[item.jsonfield] === false
              ) {
                errMsgsArray[item.dependentFields[i]] = false;
              } else if (
                !(item.dependentFields[i] in errMsgsArray) &&
                errMsgsArray[item.jsonfield] === true
              ) {
                errMsgsArray[item.dependentFields[i]] = true;
              } else if (
                item.dependentFields[i] in errMsgsArray &&
                errMsgsArray[item.dependentFields[i]] === false
              ) {
                errMsgsArray[item.dependentFields[i]] = false;
              } else {
                errMsgsArray[item.dependentFields[i]] = true;
              }

              if (
                !item.reverseCondition &&
                i === item.dependentFields?.length - 1
              ) {
                errMsgsArray[item.jsonfield] = false;
              }
            }

            if (item.reverseCondition && item.formStep === formStepId) {
              if (
                item.dependentFields[i] in errMsgsArray &&
                errMsgsArray[item.jsonfield] === true
              ) {
                errMsgsArray[item.dependentFields[i]] = false;
              } else if (
                !(item.dependentFields[i] in errMsgsArray) &&
                errMsgsArray[item.jsonfield] === true
              ) {
                errMsgsArray[item.dependentFields[i]] = false;
              } else if (
                !(item.dependentFields[i] in errMsgsArray) &&
                errMsgsArray[item.jsonfield] === false
              ) {
                errMsgsArray[item.dependentFields[i]] = true;
              } else if (
                item.dependentFields[i] in errMsgsArray &&
                errMsgsArray[item.dependentFields[i]] === false
              ) {
                errMsgsArray[item.dependentFields[i]] = false;
              } else {
                errMsgsArray[item.dependentFields[i]] = true;
              }

              if (
                item.reverseCondition &&
                i === item.dependentFields?.length - 1
              ) {
                errMsgsArray[item.jsonfield] = false;
              }
            }

            if (
              item.reverseCondition &&
              item.formStep === 'form_step_2' &&
              item?.title === 'Audited Financial Reports'
            ) {
              let hideYearIndex = null;

              if (currentYear - regYear <= 1) {
                hideYearIndex = 0;
              } else if (currentYear - regYear === 2) {
                hideYearIndex = 1;
              } else if (currentYear - regYear === 3) {
                hideYearIndex = 2;
              } else {
                hideYearIndex = null;
              }

              if (hideYearIndex !== null && item.yearFlag > hideYearIndex) {
                errMsgsArray[item.jsonfield] = false;
                errMsgsArray[item.dependentFields[i]] = false;
              }
            }
          }

          // }
        });
        nonDependentFieldsArray.map((item, index) => {
          if (item.formStep === formStepId) {
            for (let i = 0; i < item.nonDependentFields?.length; i++) {
              if (
                item.nonDependentFields[i] in errMsgsArray &&
                errMsgsArray[item.nonDependentFields[i]] === false
              ) {
                errMsgsArray[item.nonDependentFields[i]] = false;
              } else {
                errMsgsArray[item.nonDependentFields[i]] = true;
              }
            }
          }
        });
      }
      setErrMsgsArr(errMsgsArray);
    }

    //form submission;
    if (formStep1) {
      if (!Object.values(errMsgsArray).includes(true) && formStep1) {
        var jsonFormatStep1 = {
          legal_details: {
            pan_num: data.pan_num,
            img_pan: data.pan_doc,
            is_80g: data.is_80g === true ? false : true,
            number_80g: data.number_80g,
            certificate_80g:
              data.certificate_80g === undefined
                ? null
                : [data.certificate_80g],
            number_12a: data.number_12a,
            certificate_12a:
              data.certificate_12a === undefined
                ? null
                : [data.certificate_12a],
            is_csr1_registered: data.is_csr1_registered === true ? true : false,
            csr_registration_number:
              data.is_csr1_registered === true
                ? data.csr_registration_number
                : '',
            // csr1_expiry_date:
            //   data.csr1_expiry_date !== undefined ||
            //   data.is_csr1_registered === true
            //     ? dateFormatYYYYMMDD(data.csr1_expiry_date)
            //     : null,
            csr1_registration_certificate:
              data.is_csr1_registered === true
                ? data.csr1_registration_certificate
                : null
          },
          financial_details: {
            is_fcra_certified: data.is_fcra_certified === true ? true : false,
            fcra_purpose_id:
              data.fcra_purpose_id === undefined ||
              data.is_fcra_certified === false
                ? ['0']
                : data.fcra_purpose_id?.length === 1
                ? [data.fcra_purpose_id]
                : data.fcra_purpose_id,
            fcra_certificate:
              data.is_fcra_certified === true ? data.fcra_certificate : null,
            fcra_expiry_date:
              data.fcra_expiry_date !== undefined ||
              data.is_fcra_certified === true
                ? dateFormatYYYYMMDD(data.fcra_expiry_date)
                : null,
            fcra_registration_number:
              data.is_fcra_certified === true
                ? data.fcra_registration_number
                : ''
          }
        };

        props
          .savePreRegistrationDetails(jsonFormatStep1, getNgoId())
          .then(() => {
            props.getSavedNGOData(getNgoId());
            toast.success('Data saved!', {
              icon: '✅'
            });
          })
          .catch(() => {
            toast.error(
              'Uh-Oh! Looks like we are having server issues., please contact our support!',
              {
                icon: '❌'
              }
            );
          });

        setProgress(100 / 2);
        handleFormStep('form_step_2');
      }
    }

    if (formStep2) {
      setShowNextStepIndicator(false);
      if (!Object.values(errMsgsArray).includes(true)) {
        setDisableSubmitBtn(true);
        setLoadingTimer(true);
        if (loadingTimer) {
          return <Loader />;
        }
        var jsonFormatStep2 = {
          audit_item:
            financeReportArr?.length === 0
              ? [
                  {
                    report: [''],
                    year: 0
                  }
                ]
              : financeReportArr,
          tan_item: {
            tan_num: data.tan_num === undefined ? null : data.tan_num,
            tan_document:
              data.tan_document === undefined ? null : data.tan_document,
            is_tan_num: data.is_tan_num === undefined ? true : false
          },
          gst_details: {
            is_gst: data.is_gst_num ? true : false,
            gst_num: data.gst_num,
            gst_doc: data.gst_cert
          }
        };
        props
          .savePreRegistrationDetails(jsonFormatStep2, getNgoId())
          .then(res => {
            setProgress(100 / 1);
            props.systemRecommendation(
              props.state.ngo?.ngoProfile?.ngo_id
                ? props.state.ngo?.ngoProfile?.ngo_id
                : props.state?.login?.userInfo?.sub
            );
            toast.success('Data saved!', {
              icon: '✅'
            });
            sendMail({
              templateName: 'preRegSubmitAlertToAdmin',
              sendTo: 'ngo.partnerships@mettasocial.com',
              subject: 'New Pre - Registration Request Received',
              templateData: {
                ngo_name: props.state.login.userInfo.ngo_name
                  ? props.state.login.userInfo.ngo_name
                  : ''
              }
            });
            sendMail({
              templateName: 'preRegSubmitAlertToNgo',
              sendTo: props.state.login.userInfo.email,
              subject: 'New Pre - Registration Request Received',
              templateData: {}
            });
            // setTimeout(() => {
            // setLoadingTimer(false);
            handleSubmitPopup(true);
            // }, 3000);
          })
          .catch(() => {
            toast.error(
              'Uh-Oh! Looks like we are having server issues., please contact our support!',
              {
                icon: '❌'
              }
            );
          });
      }
    }
  }

  function handleCancelPopup(value) {
    setCancelPopup(value);
  }
  function handleSubmitPopup(value) {
    setSubmitPopup(value);
  }
  function fieldUpdated(stepIndex) {
    console.error(stepIndex);
  }
  const getTooltip = data => {
    if (data?.tooltip) {
      return (
        <img
          src={InfoIcon}
          width={15}
          alt="tooltip"
          height={15}
          className=" mx-3 cursor-pointer text-1c1b1d"
          data-toggle="tooltip"
          data-placement="top"
          title={data?.tooltip}
        />
      );
    }
  };
  return (
    <>
      {loadingTimer && <Loader />}
      <form
        className={surveyStatus === 'submitted' ? 'preReg-form-disabled' : ''}
        action="post"
        onSubmit={e => handleFormSubmission1(e, formStep1, formStep2)}>
        <div
          className="progress-bar progress-bar bg-success"
          role="progressbar"
          style={{ width: progress + '%' }}></div>

        {surveyStatus === 'submitted' && (
          <div className="display-note">
            <img src={underReviewGreenIcon} alt="under review" />
            <div className=" ml-5">
              <div className="text-212529 dm-bold fs-18">
                Your Profile is under review
              </div>
              <p className="text-212529 dm-regular fs-16">
                Your pre-registration profile is under review. Generally it will
                take us 3-4 working days to review a profile. You will get an
                email notification once the review is complete.
              </p>
            </div>
          </div>
        )}

        {/* step1 */}
        {formStep1 && (
          <>
            <div className="complete-registration-form">
              <section
                className={`mt-4 pageContent`}
                key={'stepIndex'}
                id={`step-container-2`}>
                {preRegistrationDataForm1.map((stepData, stepIndex) => {
                  return (
                    <>
                      <div
                        className={`text-center fs-32 text-212529 dm-medium`}>
                        {stepData.title}
                      </div>
                      <div
                        className={`text-center fs-14 text-212529 dm-regular mt-4`}>
                        {stepData.description}
                      </div>
                      {stepData.fields.map((input, index) => {
                        var hideField = false;
                        if (input.dependentOnFieldType === 'radio') {
                          var setId = 'showHide_' + input.jsonfield;
                          if (
                            Object.keys(props.state.register.ngoSavedData)
                              .length > 0
                          ) {
                            if (
                              props.state.register.ngoSavedData[
                                'at_survey_step'
                              ] !== '1'
                            ) {
                              if (input.subWrapperKey !== '') {
                                if (
                                  props.state.register.ngoSavedData?.result ===
                                  undefined
                                ) {
                                  if (
                                    props.state?.register?.ngoSavedData[
                                      input.wrapperKey
                                    ][input.subWrapperKey][
                                      input.disableDependentOn
                                    ] === false
                                  ) {
                                    hideField = true;
                                  }
                                }
                              } else {
                                if (
                                  props.state.register.ngoSavedData?.result ===
                                  undefined
                                ) {
                                  if (
                                    props.state?.register?.ngoSavedData[
                                      input.wrapperKey
                                    ][input.disableDependentOn] === false
                                  ) {
                                    hideField = true;
                                  }
                                }
                              }
                            }
                          }
                        }
                        return (
                          <>
                            {
                              <>
                                <div
                                  className={`mt-4`}
                                  hidden={hideField}
                                  id={setId}>
                                  <PreRegistrationSingleField
                                    input={input}
                                    key={`${stepIndex}-${index}`}
                                    stepIndex={stepIndex}
                                    state={props.state}
                                    showNextStepIndicator={
                                      showNextStepIndicator
                                    }
                                    errMsgsArr={errMsgsArr}
                                    setErrMsgsArr={setErrMsgsArr}
                                    errMsgsArray={errMsgsArray}
                                    fieldUpdated={fieldUpdated}
                                    surveyStatus={surveyStatus}
                                  />
                                </div>
                              </>
                            }
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </section>
            </div>
          </>
        )}
        {/* step2 */}
        {formStep2 && (
          <>
            <div className="complete-registration-form">
              <section
                className={`mt-4 pageContent`}
                key={'stepIndex'}
                id={`step-container-2`}>
                {preRegistrationDataForm2.map((stepData, stepIndex) => {
                  if (
                    registrationDate !== null &&
                    stepData.title === 'Audited Financial Reports'
                  ) {
                    const dateObj = new Date(registrationDate);
                    regYear = dateObj.getFullYear();
                    // var regYear = 2023;
                    currentYear = new Date().getFullYear();

                    if (currentYear - regYear <= 1) {
                      hideExternalAudit = true;
                    } else {
                      hideExternalAudit = false;
                    }
                  } else {
                    hideExternalAudit = false;
                  }

                  if (hideExternalAudit === false) {
                    return (
                      <>
                        <div
                          className={`text-center fs-32 text-212529 dm-medium`}>
                          {stepData.title}
                          {getTooltip(stepData)}
                        </div>
                        <div
                          className={`text-center fs-14 text-212529 dm-regular mt-4`}>
                          {stepData.description}
                        </div>
                        {stepData.fields.map((input, index) => {
                          hideAuditYearIndex = null;
                          if (stepData.title === 'Audited Financial Reports') {
                            if (currentYear - regYear === 2) {
                              hideAuditYearIndex = 1;
                            } else if (currentYear - regYear === 3) {
                              hideAuditYearIndex = 2;
                            } else {
                              hideAuditYearIndex = null;
                            }

                            return (
                              <>
                                {(hideAuditYearIndex === null ||
                                  input.yearFlag <= hideAuditYearIndex) && (
                                  <div className={`mt-4`}>
                                    <PreRegistrationSingleField
                                      input={input}
                                      key={`${stepIndex}-${index}`}
                                      stepIndex={stepIndex}
                                      showNextStepIndicator={
                                        showNextStepIndicator
                                      }
                                      state={props.state}
                                      errMsgsArr={errMsgsArr}
                                      fieldUpdated={fieldUpdated}
                                      errMsgsArray={errMsgsArray}
                                      setErrMsgsArr={setErrMsgsArr}
                                      surveyStatus={surveyStatus}
                                    />
                                  </div>
                                )}
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div className={`mt-4`}>
                                  <PreRegistrationSingleField
                                    input={input}
                                    key={`${stepIndex}-${index}`}
                                    stepIndex={stepIndex}
                                    showNextStepIndicator={
                                      showNextStepIndicator
                                    }
                                    state={props.state}
                                    errMsgsArr={errMsgsArr}
                                    fieldUpdated={fieldUpdated}
                                    errMsgsArray={errMsgsArray}
                                    setErrMsgsArr={setErrMsgsArr}
                                    surveyStatus={surveyStatus}
                                  />
                                </div>
                              </>
                            );
                          }
                        })}
                      </>
                    );
                  }
                })}
              </section>
            </div>
          </>
        )}
        <div>
          <footer className={`fixed-footer`}>
            {formStep2 &&
              (surveyStatus === 'submitted' ? (
                <label
                  className="fs-14 dm-medium pl-4 "
                  style={{ marginRight: '10rem', color: '#C1C1C1' }}>
                  Back
                </label>
              ) : (
                <Link
                  disabled={surveyStatus === 'submitted'}
                  className="pl-4 fs-14 dm-medium text-007bff"
                  style={{ marginRight: '10rem' }}
                  onClick={e => handleBackButton(e)}>
                  Back
                </Link>
              ))}
            <button
              disabled={surveyStatus === 'submitted'}
              className={`btn btn-light btn-lg w-auto ht-36 fs-14 dm-medium ml-3`}
              onClick={() => handleCancelPopup(true)}>
              Cancel
            </button>
            {formStep1 && (
              <button
                disabled={surveyStatus === 'submitted'}
                type="submit"
                className={`btn btn-primary btn-lg w-auto ht-36 fs-14 dm-medium ml-3`}
                onSubmit={e => handleFormSubmission1(e, formStepId)}>
                Save & Next
              </button>
            )}
            {formStep2 && (
              <button
                disabled={disableSubmitBtn || surveyStatus === 'submitted'}
                type="submit"
                className={`btn btn-primary btn-lg w-auto ht-36 fs-14 dm-medium ml-3`}>
                Submit
              </button>
            )}
          </footer>
        </div>
      </form>
      <PopupModal
        preRegCancel={cancelPopup}
        setPreRegCancel={setCancelPopup}
        handlePreRegCancelPopup={handleCancelPopup}
      />
      <PopupModal
        preRegSubmit={submitPopup}
        setPreRegSubmit={setSubmitPopup}
        handlePreRegSubmitPopup={handleSubmitPopup}
      />
      <PopupModal
        preRegReject={preRegReject}
        ngoRejectedDate={ngoRejectedDate}
      />
    </>
  );
}
